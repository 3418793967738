import React from 'react'
import { graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'

import Layout from '../components/layout/Layout.en'
import Seo from '../components/Seo'
import Slide from '../components/slide/Slide'
import SlideItem from '../components/slide/SlideItem'
import Service from '../components/service/Service'
import ServiceItem from '../components/service/ServiceItem'
import DownloadItem from '../components/download/DownloadItem'
import Testimonial from '../components/testimonial/Testimonial'
import TestimonialItem from '../components/testimonial/TestimonialItem'
import Statistic from '../components/statistic/Statistic'
import StatisticItem from '../components/statistic/StatisticItem'
import Media from '../components/media/Media'
import MediaItem from '../components/media/MediaItem'
import Jumbotron from '../components/jumbotron/Jumbotron'

import useSiteMetadata from '../hooks/useSiteMetadata'

import thumbnailImage from '../images/warpin-favicon.png'

const IndexPage = ({ data, location }) => {
	const { siteUrl } = useSiteMetadata()

	const sliderData = data.wpgHeadlessQuery.acfSlideBanner.slideBannerItem
	const testimonialData = data.wpgHeadlessQuery.acfTestimonial.testimonialItem
	const mediaData = data.wpgHeadlessQuery.acfMedia.mediaItem
	const seoData = data.wpgHeadlessQuery.seo

	return (
		<Layout location={location} logo={data.logoImage}>
			<Seo
				title={seoData.title}
				canonical={`${siteUrl}${location.pathname}`}
				meta={[
					{ name: 'description', content: seoData.metaDesc },
					{ name: 'keywords', content: seoData.metaKeywords },
					{
						name: 'robots',
						content: `${seoData.metaRobotsNoindex}, ${seoData.metaRobotsNofollow}`,
					},
					{ property: 'og:url', content: seoData.opengraphUrl },
					{
						property: 'og:title',
						content: `${siteUrl}${location.pathname}`,
					},
					{
						property: 'og:description',
						content: seoData.opengraphDescription,
					},
					{ property: 'og:type', content: seoData.opengraphType },
					{
						property: 'og:site_name',
						content: seoData.opengraphSiteName,
					},
					{
						property: 'og:image',
						content: seoData.opengraphImage !== null ? seoData.opengraphImage.localFile.publicURL : thumbnailImage,
					},
					{ name: 'twitter:title', content: seoData.twitterTitle },
					{
						name: 'twitter:description',
						content: seoData.twitterDescription,
					},
					{
						name: 'twitter:image',
						content: seoData.twitterImage !== null ? seoData.twitterImage.localFile.publicURL : thumbnailImage,
					},
					{ name: 'twitter:card', content: 'summary' },
					{ name: 'twitter:site', content: '@warungpintarco' },
				]}
			/>

			<section className='hero-section'>
				<Slide>
					{sliderData.map((itemData, index) => (
						<SlideItem key={index} title={itemData.title} content={itemData.content} buttonLabel={itemData.buttonLabel} buttonLink={itemData.buttonLink} imageSrc={getImage(itemData.imageSrc.localFile)} imageXsSrc={getImage(itemData.imageXsSrc.localFile)} imageAlt={itemData.imageSrc.altText} withButton={itemData.withButton} />
					))}
				</Slide>
			</section>

			<section className='feature-section'>
				<Service title='What is your business?' subtitle='Warung Pintar provides solutions that suits your business at best'>
					<ServiceItem
						title='Warungs Owner'
						buttonLabel='Check Our Apps'
						buttonLink='/en/juragan-warung/'
						imageSrc={{
							image: getImage(data.serviceImages.nodes[0]),
							width: '380px',
							widthXs: '190px',
						}}
						imageAlt='Juragan Warung Pintar'
						isPrimary={false}
					/>
					<ServiceItem
						title='Wholesaler Owner'
						buttonLabel='Learn More'
						buttonLink='/en/juragan-grosir/'
						imageSrc={{
							image: getImage(data.serviceImages.nodes[1]),
							width: '288px',
							widthXs: '200px',
						}}
						imageAlt='Juragan Grosir Warung Pintar'
						isPrimary={true}
					/>
					<ServiceItem
						title='FMCG Distributors'
						buttonLabel='Learn More'
						buttonLink='/en/distributor-fmcg/'
						imageSrc={{
							image: getImage(data.serviceImages.nodes[2]),
							width: '343px',
							widthXs: '200px',
						}}
						imageAlt='Distributor Warung Pintar'
						isPrimary={true}
					/>
					<ServiceItem
						title='Brands & Suppliers'
						buttonLabel='Learn More'
						buttonLink='/en/brand-supplier/'
						imageSrc={{
							image: getImage(data.serviceImages.nodes[3]),
							width: '283px',
							widthXs: '192px',
						}}
						imageAlt='Brand and supplier warung pintar'
						isPrimary={true}
					/>
				</Service>
			</section>

			<section className='testimonial-section'>
				<Testimonial title="Juragan Pintar's Testimony" subtitle={null} isInverse={false} isSecondary={false}>
					{testimonialData.map((itemData, index) => (
						<TestimonialItem key={index} content={itemData.content} name={itemData.name} description={itemData.description} iconSrc={getImage(data.grosirPintarIcon)} imageSrc={getImage(itemData.imageSrc.localFile)} imageAlt={itemData.imageAlt} isGrosirPintar={itemData.isGrosirPintar} />
					))}
				</Testimonial>
			</section>

			<section className='statistic-section'>
				<Statistic title='Since 2017, Warung Pintar has grown across Indonesia '>
					<div className='columns is-multiline'>
						<div className='column is-12-widescreen is-12-desktop is-12-tablet'>
							<StatisticItem
								title='500.000+'
								subtitle='Warungs Registered'
								subtitleMobile='Warungs Registered'
								imageSrc={{
									image: getImage(data.statisticImages.nodes[0]),
									class: 'statistic-image-01',
								}}
								imageAlt='500.000+ warung gabung warung pintar'
								isFit={false}
							/>
						</div>
						<div className='column is-8-widescreen is-12-desktop is-12-tablet'>
							<StatisticItem
								title='500+'
								subtitle='Brands and Suppliers'
								subtitleMobile='Brands and Suppliers'
								imageSrc={{
									image: getImage(data.statisticImages.nodes[1]),
									class: 'statistic-image-02',
								}}
								imageAlt='500+ brand gabung warung pintar'
								isFit={false}
							/>
						</div>
						<div className='column is-4-widescreen is-12-desktop is-12-tablet'>
							<StatisticItem
								title='1000+'
								subtitle='Grosir <br /> Pintar'
								subtitleMobile='Grosir Pintar'
								imageSrc={{
									image: getImage(data.statisticImages.nodes[2]),
									class: 'statistic-image-03',
								}}
								imageAlt='1000+ grosir gabung warung pintar'
								isFit={true}
							/>
						</div>
						<div className='column is-6-widescreen is-12-desktop is-12-tablet'>
							<StatisticItem
								title='200+'
								subtitle='Cities and Regencies'
								subtitleMobile='Cities and Regencies'
								imageSrc={{
									image: getImage(data.statisticImages.nodes[3]),
									class: 'statistic-image-04',
								}}
								imageAlt='200+ titik jangkauan warung pintar'
								isFit={false}
							/>
						</div>
						<div className='column is-6-widescreen is-12-desktop is-12-tablet'>
							<StatisticItem
								subtitle='52 Warehouses <br /> and 5 Depots'
								subtitleMobile='52 Warehouses and 5 Depots'
								imageSrc={{
									image: getImage(data.statisticImages.nodes[4]),
									class: 'statistic-image-05',
								}}
								imageAlt='gudang warung pintar'
								isFit={true}
							/>
						</div>
					</div>
				</Statistic>
			</section>

			<section className='jumbotron-section'>
				<Jumbotron title='Install <br /> Warung Pintar App' subtitle='Need assistance?' buttonPrimaryLabel='Install Now' buttonPrimaryLink='https://play.google.com/store/apps/details?id=co.warungpintar.mitra' buttonSecondaryLabel='Contact our CS' buttonSecondaryLink='https://wa.me/6285157577630' imageSrc={getImage(data.appBackground)} />
			</section>
		</Layout>
	)
}

export default IndexPage

export const queryData = graphql`
	{
		wpgHeadlessQuery(slug: { eq: "beranda-en" }) {
			id
			slug
			seo {
				canonical
				title
				metaDesc
				metaKeywords
				metaRobotsNofollow
				metaRobotsNoindex
				opengraphDescription
				opengraphSiteName
				opengraphTitle
				opengraphType
				opengraphUrl
				opengraphImage {
					localFile {
						publicURL
					}
				}
				twitterTitle
				twitterDescription
				twitterImage {
					localFile {
						publicURL
					}
				}
			}
			acfSlideBanner {
				slideBannerItem {
					buttonLabel
					buttonLink
					content
					fieldGroupName
					title
					withButton
					imageSrc {
						altText
						localFile {
							childImageSharp {
								gatsbyImageData(width: 1000)
							}
						}
					}
					imageXsSrc {
						localFile {
							childImageSharp {
								gatsbyImageData(width: 800)
							}
						}
					}
				}
			}
			acfTestimonial {
				testimonialItem {
					content
					name
					description
					isGrosirPintar
					imageAlt
					imageSrc {
						localFile {
							childImageSharp {
								gatsbyImageData(width: 600)
							}
						}
					}
				}
			}
			acfMedia {
				mediaItem {
					content
					buttonLabel
					buttonLink
					iconSrc {
						localFile {
							childImageSharp {
								gatsbyImageData(width: 500)
							}
						}
					}
					imageSrc {
						localFile {
							childImageSharp {
								gatsbyImageData(width: 1000)
							}
						}
					}
					imageXsSrc {
						localFile {
							childImageSharp {
								gatsbyImageData(width: 600)
							}
						}
					}
				}
			}
		}

		serviceImages: allFile(filter: { relativeDirectory: { eq: "illustrations/services" } }, sort: { order: ASC, fields: [name] }) {
			nodes {
				childImageSharp {
					gatsbyImageData(width: 400)
				}
			}
		}
		downloadImages: allFile(filter: { relativeDirectory: { eq: "banners/downloads" } }, sort: { order: ASC, fields: [name] }) {
			nodes {
				childImageSharp {
					gatsbyImageData(width: 400)
				}
			}
		}
		statisticImages: allFile(filter: { relativeDirectory: { eq: "illustrations/statistics" } }, sort: { order: ASC, fields: [name] }) {
			nodes {
				childImageSharp {
					gatsbyImageData(width: 800)
				}
			}
		}
		mediaImages: allFile(filter: { relativeDirectory: { eq: "medias" } }, sort: { order: ASC, fields: [name] }) {
			nodes {
				childImageSharp {
					gatsbyImageData(width: 200, transformOptions: { grayscale: true })
				}
			}
		}
		appImage: file(relativePath: { eq: "apps/downloads/wpg-app-01.png" }) {
			childImageSharp {
				gatsbyImageData(width: 800)
			}
		}
		appBackground: file(relativePath: { eq: "apps/jumbotrons/wpg-app-01.png" }) {
			childImageSharp {
				gatsbyImageData(width: 800)
			}
		}
		grosirPintarIcon: file(relativePath: { eq: "icons/wpg-icon-06.png" }) {
			childImageSharp {
				gatsbyImageData(width: 600)
			}
		}
		googlePlayIcon: file(relativePath: { eq: "icons/google-play-icon.png" }) {
			childImageSharp {
				gatsbyImageData(width: 200)
			}
		}
		logoImage: file(relativePath: { eq: "warpin-logo-inverse.png" }) {
			childImageSharp {
				gatsbyImageData(width: 800)
			}
		}
	}
`
